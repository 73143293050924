:local(.message) {
    text-align: center;
    padding: var(--spacing4x) var(--spacing);
}

:local(.page) {
    padding: var(--spacing);

    :local(.secAction) {
        float: right;
    }

    :local(.breadcrumb) {
        margin-top: var(--spacing);
        margin-bottom: -20px;
        font-size: var(--small);
        color: var(--gray2);

        :local(.el::after) {
            content: " » "
        }

        :local(.el:last-child::after) {
            content: ""
        }
    }

    :local(.title) {
        margin: var(--spacing) 0px;
        font-size: var(--big4x);
        color: var(--gray2);
    }

    :local(.subtitle) {
        margin: var(--spacing) 0px;
        font-size: var(--small);
        color: var(--gray2);
    }

    :local(.title+.subtitle) {
        margin-top: -20px;
    }

    :local(.sectionHeader) {
        margin: var(--spacing) 0px;
        padding: 0;
        font-size: var(--big);
        color: var(--gray2);

    }

    :local(.sectionHeaderInfo) {
        margin: 0px 0px var(--spacing);
        padding: 0;
        margin-top: -20px;
        font-size: var(--normal);
        color: var(--gray4);

    }

    :local(.loading) {
        padding: 100px 0;
        text-align: center;
    }

}

:local(.events) {
    font-size: 12px;

    :local(.event) {
        padding: 10px;
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 20px;

        :local(.date) {}

        :local(.text) {}
    }
}

:local(.link) {
    color: var(--mainColor);
    cursor: pointer;
    text-decoration: underline;
}

:local(.small) {
    font-size: var(--small);
}

@media(max-width:640px) {
    :local(.page) {
        padding: var(--spacing_half);
    }
}


:local(.table) {
    border-collapse: collapse;
    width: 100%;

    th {
        text-align: left;
        border-bottom: 2px solid #ccc;
        padding: var(--spacing_half);
    }

    td {
        padding: var(--spacing_half);
        border-bottom: 1px solid #eee;

        &:local(.right) {
            text-align: right;
        }
    }

    tr:last-child {

        td,
        th {
            border-bottom: 0;
        }
    }
}

:local(.tableSmall) {
    th {
        font-size: var(--small);
        padding: var(--spacing_quarter);
    }

    td {
        padding: var(--spacing_quarter);
        font-size: var(--small);
    }
}

:local(.tableSemiSmall) {
    th {
        font-size: var(--small);
    }

    td {
        font-size: var(--small);
    }
}