:local(.input){
    margin-top:var(--spacing);
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap:var(--spacing_half);
}
:local(.actions){
    margin-top:var(--spacing);
    margin-bottom:var(--spacing);
}
:local(.table){
    margin-top:var(--spacing);
    margin-bottom:var(--spacing);
}