:root {
  --mainColor: #54D1EF;
  --mainColorTr0: #963D9708;
  --mainColorTr: #963D9716;
  --mainColorTr2: #963D9732;
  --gray1: #333;
  --gray2: #444;
  --gray3: #777;
  --gray4: #999;
  --gray5: #ccc;
  --gray6: #eee;
  --gray7: #f5f5f5;
  --maxWidth: 1300px;
  --antd-wave-shadow-color: var(--mainColor);

  --small2x: .7rem;
  --small: .8rem;
  --normal: 1rem;
  --big: 1.2rem;
  --big2x: 1.4rem;
  --big4x: 1.6rem;
  --big8x: 2rem;
  --big16x: 3rem;

  --spacing: 20px;
  --spacing2x: 40px;
  --spacing4x: 80px;
  --spacing_half: 10px;
  --spacing_quarter: 5px;
  --radius: 3px;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media(min-width:640px) {
  .ant-form-item-control {
    margin-bottom: var(--spacing_half);
  }

  .ant-form-item {
    margin-bottom: var(--spacing_half);
  }

  .ant-form-vertical .ant-form-item-label {
    padding-bottom: 0;

  }
}

a {
  color: var(--mainColor);
  text-decoration: underline;
}

a:hover {
  color: var(--mainColor);
}


/*

.ant-btn-primary, 
.ant-btn-primary:hover,
.ant-btn-primary:focus{
  background-color: var(--mainColor);
  border-color: var(--mainColor);
  border-radius: 3px;
}

.ant-btn-secondary, 
.ant-btn-secondary:hover,
.ant-btn-secondary:focus{
  color: var(--mainColor);
  border-color: var(--mainColor);
  border-radius: 3px;
}

.ant-btn-whatsapp,
.ant-btn-whatsapp:hover,
.ant-btn-whatsapp:focus{
  background-color: #07bc4c;
  border-color: #07bc4c;
  color:#fff;
  border-radius: 3px;
}



.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn, 
.ant-tabs-tab:hover,
.ant-tabs-tab:active,
.ant-tabs-tab:focus
{
  color: var(--mainColor);
}

.ant-tabs-ink-bar{
  background: var(--mainColor);
}


::selection {
  background: var(--mainColor);
}

.ant-spin-dot-item{
  background: var(--mainColor);
}

.ant-upload.ant-upload-drag .ant-upload{
  padding:0;
}
.ant-upload.ant-upload-drag{
  border:0;
}
*/