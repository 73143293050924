:local(.cnt) {
  cursor:pointer;
}

:local(.children) {
  display: inline-block;
}
:local(.message) {
  margin-left:var(--spacing_half);
  display: inline-block;
}