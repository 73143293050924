:local(.form) {
  display: grid;
  grid-template-columns: 1fr 500px;
  gap: 20px;

  :local(.formMain) {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
  }

  :local(.formSidebar) {
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
  }
}

@media (max-width: 640px) {
  :local(.form) {
    display: grid;
    grid-template-columns: 1fr;

    :local(.formMain) {
      grid-column: 1 / span 1;
      grid-row: 1 / span 1;
    }

    :local(.formSidebar) {
      grid-column: 1 / span 1;
      grid-row: 2 / span 1;
    }
  }
}

:local(.calendly) {
  font-size: 14px;
}

:local(.calendly_item) {
  margin-top: var(--spacing_half);
}

:local(.calendly_questions) {
  margin: var(--spacing) 0;
}

:local(.calendly_question) {
  border-bottom: 1px solid #eee;
  &:last-child {
    border: 0;
  }
  padding: var(--spacing_half);
  display: grid;
  grid-template-columns: 200px 1fr;
  :local(.q) {
    font-weight: 500;
    color: #666;
  }
  :local(.a) {
    margin-left: var(--spacing_quarter);
    color: #222;
  }
}
