:local(.form){
    display:grid;
    grid-template-columns: 3fr 1fr;
    gap:20px;
    :local(.formMain){
        grid-column: 1/span 1;
        grid-row: 1/span 1;
    }

    :local(.formSidebar){
        grid-column: 2/span 1;   
        grid-row: 1/span 1;
    }
}


@media(max-width:640px){
    :local(.form){
        display:grid;
        grid-template-columns: 1fr;   
        :local(.formMain){
            grid-column: 1/span 1;
            grid-row: 1/span 1;
        }
        
        :local(.formSidebar){
            grid-column: 1/span 1;   
            grid-row: 2/span 1;
        }
    }        
}