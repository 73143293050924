:local(.tableFiles){
  border-collapse: collapse;
  width:100%;
  tr{
      border-bottom:1px solid var(--gray6);
  }
  td{
      padding:var(--spacing_half);
      
      img{
          margin-right: var(--spacing_half);
      }
  }
  :local(.fileInfo){
    display:grid;
    grid-template-columns: auto 1fr;
    gap: var(--spacing_half);
  }
  :local(.changeName){
    cursor: pointer;
    text-decoration: underline;
  }
}

:local(.uploader){
  cursor: pointer;
  background:var(--gray7);
  border:1px solid var(--gray6);
  border-radius:5px;overflow:hidden;
  display: block;
  padding:0;
  width:100%;
  :local(.uploaderInner){
      text-align: center;
      padding: 50px;
  }
  
}
:local(.fileDetail){
  text-align:left;
  width:100%;
  height:40px;
  border-bottom:1px solid var(--gray6);
  position:relative;
  &:last-child{
      border:0;
  }
}
:local(.fileDetailInner){
  z-index:2;
  display:grid;
  position:absolute;
  gap:var(--spacing_half);
  top:0;
  left:0;
  bottom:0;
  padding:var(--spacing_half) var(--spacing_quarter) var(--spacing_half) var(--spacing);
  
  right:0;
  grid-template-columns: auto auto 1fr auto;
}
:local(.fileDetailProgress){
  z-index:1;
  position:absolute;
  top:0;
  left:0;
  bottom:0;
  background:#f0f0f0;
}
:local(.fileDetailPercent){
  font-size:12px;
  font-weight: 600;
  margin-top:-2px;
  background:#696;
  border-radius: 100px;
  padding:3px 10px;
  color:#fff;
}
:local(.fileDelete){
  color:#c30;
}