:local(.cnt) {
    margin: 0;
    padding: 0;
}

:local(.login) {
    width: 100%;
    margin: auto;
}

:local(.logo) {
    display: block;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 35px;

    img {
        height: 50px;
    }
}


:local(.login .form) {
    margin: 0px;
    padding: var(--spacing);
}


@media(max-width:640px) {
    :local(.cnt) {
        grid-template-columns: 1fr;
    }

    :local(.sidebar) {
        grid-column: 1/span 1;
    }
}